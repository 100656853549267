import { OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { AppearanceEditor } from '@common/admin/appearance/appearance-editor/appearance-editor.service';
import { Settings } from '@common/core/config/settings.service';
import { map } from 'rxjs/operators';
import * as Dot from 'dot-object';
var CONFIG_KEY = 'homepage.appearance';
var HomepageAppearancePanelComponent = /** @class */ (function () {
    function HomepageAppearancePanelComponent(fb, editor, settings) {
        this.fb = fb;
        this.editor = editor;
        this.settings = settings;
        this.selectedSubpanel$ = new BehaviorSubject(null);
        this.path$ = this.selectedSubpanel$.pipe(map(function (panel) {
            var path = ['Homepage'];
            if (panel)
                path.push(panel);
            return path;
        }));
        this.form = this.fb.group({
            headerTitle: [''],
            headerSubtitle: [''],
            footerTitle: [''],
            footerSubtitle: [''],
            actions: this.fb.group({
                inputText: [''],
                inputButton: [''],
                cta1: [''],
                cta2: [''],
            }),
            primaryFeatures: this.fb.array([]),
            secondaryFeatures: this.fb.array([]),
        });
    }
    HomepageAppearancePanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        var data = this.settings.getJson(CONFIG_KEY);
        this.defaultValues = JSON.parse(this.editor.defaultSettings[CONFIG_KEY]);
        data.primaryFeatures.forEach(function () {
            _this.addFeature('primary');
        });
        data.secondaryFeatures.forEach(function () {
            _this.addFeature('secondary');
        });
        this.form.patchValue(data);
        this.form.valueChanges.subscribe(function (value) {
            var _a;
            _this.editor.setConfig(CONFIG_KEY, value);
            _this.editor.addChanges((_a = {}, _a[CONFIG_KEY] = value, _a));
        });
    };
    HomepageAppearancePanelComponent.prototype.openPreviousPanel = function () {
        if (this.selectedSubpanel$.value) {
            this.openSubpanel(null);
        }
        else {
            this.editor.closeActivePanel();
        }
    };
    HomepageAppearancePanelComponent.prototype.openSubpanel = function (name) {
        this.selectedSubpanel$.next(name);
    };
    HomepageAppearancePanelComponent.prototype.addFeature = function (type) {
        var features = this.form.get(type + "Features");
        var data = { title: [''], subtitle: [''], image: [''] };
        if (type === 'secondary') {
            data.description = [''];
        }
        features.push(this.fb.group(data));
    };
    HomepageAppearancePanelComponent.prototype.removeFeature = function (type, index) {
        var features = this.form.get(type + "Features");
        features.removeAt(index);
    };
    HomepageAppearancePanelComponent.prototype.defaultValue = function (key) {
        return Dot.pick(key, this.defaultValues) || '';
    };
    HomepageAppearancePanelComponent.prototype.primaryArray = function () {
        return this.form.get('primaryFeatures');
    };
    HomepageAppearancePanelComponent.prototype.secondaryArray = function () {
        return this.form.get('secondaryFeatures');
    };
    return HomepageAppearancePanelComponent;
}());
export { HomepageAppearancePanelComponent };

import { DomSanitizer } from '@angular/platform-browser';
var LinkFrameComponent = /** @class */ (function () {
    function LinkFrameComponent(sanitizer) {
        this.sanitizer = sanitizer;
    }
    Object.defineProperty(LinkFrameComponent.prototype, "link", {
        get: function () {
            return this._link;
        },
        set: function (link) {
            this._link = link;
            this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.link.long_url);
        },
        enumerable: true,
        configurable: true
    });
    return LinkFrameComponent;
}());
export { LinkFrameComponent };

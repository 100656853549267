import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
var BASE_URI = 'link';
var LinkService = /** @class */ (function () {
    function LinkService(http) {
        this.http = http;
    }
    LinkService.prototype.index = function (params) {
        if (params === void 0) { params = {}; }
        return this.http.get("" + BASE_URI, params);
    };
    LinkService.prototype.get = function (id, params) {
        if (params === void 0) { params = {}; }
        return this.http.get(BASE_URI + "/" + id, params);
    };
    LinkService.prototype.create = function (params) {
        return this.http.post(BASE_URI, params);
    };
    LinkService.prototype.update = function (id, params) {
        return this.http.put(BASE_URI + "/" + id, params);
    };
    LinkService.prototype.delete = function (ids) {
        return this.http.delete(BASE_URI + "/" + ids);
    };
    LinkService.prototype.getCurrentUserReports = function (params) {
        if (params === void 0) { params = {}; }
        return this.http.get(BASE_URI + "/reports", params);
    };
    LinkService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LinkService_Factory() { return new LinkService(i0.ɵɵinject(i1.AppHttpClient)); }, token: LinkService, providedIn: "root" });
    return LinkService;
}());
export { LinkService };
